export const AUTHENTICATE = 'IMPORT_BOARD_AUTHENTICATE';
export const AUTHENTICATE_FAILED = 'IMPORT_BOARD_AUTHENTICATE_FAILED';
export const AUTHENTICATE_SUCCESS = 'IMPORT_BOARD_AUTHENTICATE_SUCCESS';

export const LOAD_DETAILS = 'IMPORT_BOARD_LOAD_DETAILS';
export const LOAD_DETAILS_DONE = 'IMPORT_BOARD_LOAD_DETAILS_DONE';

export const IMPORT_BOARDS = 'IMPORT_BOARD_IMPORT_BOARDS';
export const IMPORT_BOARDS_FAILED = 'IMPORT_BOARD_IMPORT_BOARDS_FAILED';
export const IMPORT_BOARDS_SUCCESS = 'IMPORT_BOARD_IMPORT_BOARDS_SUCCESS';

export const UPLOAD_FILE = 'IMPORT_BOARD_UPLOAD_FILE';
export const UPLOAD_FILE_FAILED = 'IMPORT_BOARD_UPLOAD_FILE_FAILED';
export const UPLOAD_FILE_SUCCESS = 'IMPORT_BOARD_UPLOAD_FILE_SUCCESS';

export const RESET_DATA = 'IMPORT_BOARD_RESET_DATA';

/**
 * Dispatch this action to start a Trello authentication process and get token of trello.
 */
export const authenticate = (source, accountId) => {
  return {
    type: AUTHENTICATE,
    source,
    accountId
  }
}

export const _authenticateFailed = (error) => {
  return {
    type: AUTHENTICATE_FAILED,
    error
  }
}

export const _authenticateSuccess = (token) => {
  return {
    type: AUTHENTICATE_SUCCESS,
    token
  }
}

/**
 * Dispatch this action to load trello board-list.
 */
export const loadDetails = () => {
  return {
    type: LOAD_DETAILS
  }
}

export const _loadDetailsDone = (data) => {
  return {
    type: LOAD_DETAILS_DONE,
    data
  }
}

/**
 * Dispatch this action to import trello boards.
 */
export const importBoards = (boards, uninvitedMemberCount, accountId) => {
  return {
    type: IMPORT_BOARDS,
    boards,
    uninvitedMemberCount,
    accountId
  }
}

export const _importBoardsFailed = (error) => {
  return {
    type: IMPORT_BOARDS_FAILED,
    error
  }
}

export const _importBoardsSuccess = (accountId) => {
  return {
    type: IMPORT_BOARDS_SUCCESS,
    accountId
  }
}

/**
 * Dispatch this action to reset import board data.
 */
export const resetImportBoardData = () => {
  return {
    type: RESET_DATA
  }
}

/**
 * Dispatch this action to upload file.
 */
export const uploadFile = (file) => {
  return {
    type: UPLOAD_FILE,
    file
  }
}

export const _uploadFileFailed = (error) => {
  return {
    type: UPLOAD_FILE_FAILED,
    error
  }
}

export const _uploadFileSuccess = () => {
  return {
    type: UPLOAD_FILE_SUCCESS
  }
}