import { LitElement } from "@dreamworld/pwa-helpers/lit-element.js";
import { html, css } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { connect } from '@dreamworld/pwa-helpers/connect-mixin';
import i18next from '@dw/i18next-esm';
import localize from '@dw/pwa-helpers/localize';
import { store } from '../store.js';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import debounce from 'lodash-es/debounce';
import merge from 'lodash-es/merge';
import filter from 'lodash-es/filter';
import * as auth from '../redux/auth';
import * as knownFeatures from '../redux/known-features';
import * as search from '../redux/search';
import * as amplitude from '../analytics/amplitude.js';
import { config } from '../redux/known-features/config.js';
import '@dreamworld/dw-tooltip';
import '../components/common/kerika-button.js';

/**
 * This is a Tip Manager component which manage to show or hide tip based on event and `show` and `hide` public method.
 * It manage pending tips queue and queue will be processed after 300 ms once it get notification to display pending tip.
 * It generates Tip based on feature name and display to user on given element of user had not known this feature after 300 ms.
 * It never display Tip if user is not logged in or user had discover thi feature.
 *
 * ## Overview
 *  - When it is attached, it start listing on window's events (`show-tip` and `hide-tip`)
 *  - It read login user id from redux state and when it is avialable then request to load user's knonw features.
 *  - On `show-tip`
 *    - If user is not logged in or knonw features are not ready then
 *       - Set event data into `_pendingTips`. Once data is ready then it display pending tip based on user's knonw feature.
 *    - If user is logged in and knonw features are ready then
 *      - If user had not achieve this feature then display tip otherwise do nothing
 *  - ON `hide-tip`
 *    - Dispatch action to add feature to known list of user
 *    - reset `_currentTip` to destroy current tip
 *  - It also consider priority of tip. If there is a request to display tip which has high priority then current tip then
 *    hide current tip and display new high priority tap. (Lower number is considered as High priority)
 *  ## Tip buttons rules
 *  we need to finalise UI specification for the Tip, so everyone can understand it and apply it properly. We may need to think about followings:
 *    1. A Tip's action buttons has following possibilities:
 *        a. No Action Buttons
 *        b. Only 1 Action Button (Positive input -> Followed Tip).
 *        c. Only 1 Action Button (Negative input -> Don't want to follow Tip; and it shouldn't be presented me again either).    d. 2 Action Buttons (1 for Positive input -> Followed Tip; other for Negative input -> Didn't follow tip, e.g. SKIP)
 *
 *    2. When a Tip will have a SKIP button, and when it won't have?
 *        a. If Tip is presented as a part of a Tip Wizard and a particular step is to be skipped then it's needed. e.g. workflow tip
 *        b. If a Tip is presented until user doesn't use/explore the feature. And we want to provide an explicit way to user to skip that Tip, without exploring the feature. Then SKIP button is needed.
 *        c. If a Tip is presented only once then no need of SKIP button. e.g. Card Detail dialog >. Description Tip.
 *
 *   Based on the above UI rules could be as follows:
 *    1. Positive Action Button should be on the right and in Yellow color.
 *    2. Negative Action button should be on the left and in White Color.
 *
 *    in workflow tip and other Guide Tips, SKIP THIS STEP button would be in White color and will stay on the left.
 *    In info tip - board settings dialog LEARN MORE button will be Yellow and on the right side.
 */


class FeatureTooltip extends connect(store)(localize(i18next)(LitElement)) {
  static get styles() {
    return [
      css`
        :host {
          display: block;
        }
      `
    ];
  }

  /**
   * Tip styles which is append to body when this compoment is attached
   * because tip dom is added into directly body.
   */
  get tipStyles() {
    return css`
      .tippy-box[data-theme="material"] {
        background: #505355;
        border-radius: 4px;
        color: #FFF;
        padding: 8px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.4px;
        line-height: 16px;
      }

      .tippy-box[data-theme="blue"] {
        border-radius: 4px;
        padding: 20px 20px 12px 20px;
        width: 340px;
        background-color: #2196f3;
        color: #FFF;
        text-align: left;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.0), 0px 2px 2px 0px rgba(0,0,0,0.50);
        -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.0), 0px 2px 2px 0px rgba(0,0,0,0.50);
        -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.0), 0px 2px 2px 0px rgba(0,0,0,0.50);
      }

      .tippy-box[data-theme="blue"][whole-tip-clickable=true],
      .tippy-box[data-theme="blue"][whole-tip-clickable=true] .tippy-content .title,
      .tippy-box[data-theme="blue"][whole-tip-clickable=true] .tippy-content .sub-title,
      .tippy-box[data-theme="blue"][whole-tip-clickable=true] .tippy-content .message,
      .tippy-box[data-theme="blue"][whole-tip-clickable=true] .tippy-content .actions {
        cursor: pointer;
      }

      .tippy-box[data-placement^=top] .tippy-arrow {
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        bottom: -10px
      }

      .tippy-box[data-theme="blue"][data-placement^=top] .tippy-arrow {
        border-top: 10px solid #2196f3;
      }

      .tippy-box[data-theme="material"][data-placement^=top] .tippy-arrow {
        border-top: 10px solid #505355;
      }

      .tippy-box[data-placement^=bottom] .tippy-arrow {
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        top: -8px;
      }

      .tippy-box[data-theme="blue"][data-placement^=bottom] .tippy-arrow {
        border-bottom: 10px solid #2196f3;
      }

      .tippy-box[data-theme="material"][data-placement^=bottom] .tippy-arrow {
        border-bottom: 10px solid #505355;
      }

      .tippy-box[data-placement^=right] .tippy-arrow {
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        left: -15px;
        transform: translate3d(0px, 72.8px, 0px) rotate(270deg) !important;
      }

      .tippy-box[data-theme="blue"][data-placement^=right] .tippy-arrow {
        border-bottom: 10px solid #2196f3;
      }

      .tippy-box[data-theme="material"][data-placement^=right] .tippy-arrow {
        border-bottom: 10px solid #505355;
      }

      .tippy-box[data-theme="blue"] .tippy-content .title {
        margin-bottom: 12px;
        font-family: 'Roboto', 'Noto', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        font-weight: 500;
        cursor: default;
      }

      .tippy-box[data-theme="blue"] .tippy-content .sub-title {
        margin-bottom: 8px;
        font-family: 'Roboto', 'Noto', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        cursor: default;
      }

      .tippy-box[data-theme="blue"] .tippy-content .message {
        font-family: 'Roboto', 'Noto', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        cursor: default;
        margin-bottom: 8px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .image {
        height: 200px;
        margin-bottom: 8px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .image img {
        height: 200px;
        width: 100%;
      }

      .tippy-box[data-theme="blue"] .tippy-content .image[mobile-layout] img {
        object-fit: contain;
      }

      .tippy-box[data-theme="blue"] .tippy-content .image[has-actions-button] {
        margin-bottom: 0px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .message[has-actions-button] {
        margin-bottom: 0px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .message[has-image] {
        margin-bottom: 16px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .message p {
        margin: 4px 0px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .message p:first-child {
        margin-top: 0px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .message p:last-child {
        margin-bottom: 0px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .actions {
        margin-top: 16px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .tippy-box[data-theme="blue"] .tippy-content .actions[new-style] {
        margin-top: 4px;
        margin-bottom: 0px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .actions[center-button-style] {
        justify-content: center;
      }

      .tippy-box[data-theme="blue"] .tippy-content a.primary-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto', 'Noto', sans-serif;
        font-weight: 500;
        display: inline-block;
        white-space: nowrap;
        background-color: #fff;
        color: #c11e5c;
        height: 32px;
        line-height: 30px;
        text-decoration: none;
        padding: 0px 16px;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;

        border-radius: 2px;
        -webkit-border-radius: 2px;
        -ms-border-radius: 2px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .actions[new-style] kerika-button {
        --mdc-theme-primary: #fff;
        font-weight: 400;
        font-size: 18px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .actions[new-style] kerika-button.primary-button {
        --mdc-theme-primary: #F8E71C;
      }

      .tippy-box[data-theme="blue"] .tippy-content .actions[new-style] kerika-button.secondary-button {
        margin-left: -16px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .actions[new-style] kerika-button.primary-button {
        margin-right: -16px;
      }

      .tippy-box[data-theme="blue"] .tippy-content .actions[only-primary-action][new-style] kerika-button.primary-button {
        margin-right: 0px;
        margin-left: -16px;
      }

      .tippy-box[data-animation=shift-away][data-state=hidden] {
        opacity: 0
      }

      .tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=top] {
        transform: translateY(10px)
      }

      .tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=bottom] {
        transform: translateY(-10px)
      }

      .tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=left] {
        transform: translateX(10px)
      }

      .tippy-box[data-animation=shift-away][data-state=hidden][data-placement^=right] {
        transform: translateX(-10px)
      }

      .tippy-box *[hidden], .tippy-box [hidden] {
        display: none !important;
      }
    `
  }

  constructor(){
    super();
    this.i18nextNameSpaces = ['tip'];

    //Append Tip style into body
    var sheet = document.createElement('style');
    sheet.innerHTML = this.tipStyles.cssText;
    document.body.appendChild(sheet);

    /**
     * Pending tips detail
     *
     * It is a queue of all tips which is requested to display.
     * Tip is added into queue when master data is not ready or queue is already in process
     *
     * It will always reset once queue is processed.
     */
    this._pendingTips = [];

    /**
     * Extra options of `dw-tooltip`
     */
    const self = this;
    this._extraOptions = {
      arrow: true,
      showOnCreate: true,
      interactive: true,
      hideOnClick: false,
      appendTo: document.body,
      onShown(instance) {
        self.__invokedPrimaryAction = false;
        self.__invokedSecondaryAction = false;
        self.__invokedOutsideClick = false;
        self.__invokedReferenceClick = false;
        const reference = instance && instance.reference;
        const popper = instance && instance.popper;
        reference && reference.addEventListener('mousedown', (e) => {
          self.__invokedReferenceClick = true;
          if(self._isCancelOnOutsideClick()) {
            instance.hide();
          }
        }, { once: true });

        reference && reference.addEventListener('pointerdown', (e) => {
          self.__invokedReferenceClick = true;
          if(self._isCancelOnOutsideClick()) {
            instance.hide();
          }
        }, { once: true });

        popper && popper.addEventListener('mousedown', (e) => {
          if(self._isWholeTipClickable()) {
            self.__clickOnTip = true;
            instance.hide();
          }
        }, { once: true });

        //primary button mouse down bind
        const primaryBtn = instance.popper.querySelector('.primary-button');
        primaryBtn && primaryBtn.addEventListener('mousedown', (e) => {
          self.__invokedPrimaryAction = true;
          instance.hide();
        }, { once: true });

        //secondary button mouse down bind
        const secondaryBtn = instance.popper.querySelector('.secondary-button');
        secondaryBtn && secondaryBtn.addEventListener('mousedown', (e) => {
          self.__invokedSecondaryAction = true;
          instance.hide();
        }, { once: true });
      },
      onClickOutside(instance) {
        self.__invokedOutsideClick = true;
        if(self._isCancelOnOutsideClick()) {
          instance.hide();
        }
      },
      onHidden() {
        self.hide();
      }
    }

    //Debounce handler of process pending tip queue to display one of the tip which feature is not known to user
    this.__showTipOnWindowEvent = this.__showTipOnWindowEvent.bind(this);
    this.__hideTipOnWindowEvent = this.__hideTipOnWindowEvent.bind(this);
    this._processPendingTip = debounce(this._processPendingTip, 300);
  }

  /**
   * Instance properties
   */
  static get instanceProps() {
    return {
      _pendingTips: true,
      _extraOptions: true,
      _processPendingTip: true
    }
  }

  static get properties() {
    return {
      /**
       * Possible values: PAGE, DIALOG
       */
      for: { type: String },

      /**
       * Hide currently opene tip using z-index=-1.
       */
      hideTip: { type: Boolean },

      /**
       * Current layout is wide or not.
       */
      wideLayout: { type: Boolean, reflect: true, attribute: 'wide-layout' },

      /**
       * Name of Achivement which must be one of `KNOWN_FEATURES`.
       */
      _currentTip: { type: Object },

      /**
       * `true` when known-featuers are loaded.
       */
      _isLoaded: { type: Boolean },

      /**
       * user is impersonated user or not.
       */
      _impersonatedUser: { type: Boolean }
    };
  }

  set _isLoaded(val) {
    let oldValue = this.__isLoaded;
    if (val === oldValue) {
      return;
    }
    this.__isLoaded = val;
    this.requestUpdate('_isLoaded', oldValue);
    val && this._processPendingTip();
  }

  get _isLoaded() {
    return this.__isLoaded;
  }

  set _impersonatedUser(val) {
    let oldValue = this.__impersonatedUser;
    if (val === oldValue) {
      return;
    }
    this.__impersonatedUser = val;
    this.requestUpdate('_impersonatedUser', oldValue);
    !val && this._processPendingTip();
  }

  get _impersonatedUser() {
    return this.__impersonatedUser;
  }

  connectedCallback() {
    super.connectedCallback && super.connectedCallback();
    //Listen on window events
    window.addEventListener('show-tip',this.__showTipOnWindowEvent);
    window.addEventListener('hide-tip', this.__hideTipOnWindowEvent);
  }

  render() {
    return html `${(!isEmpty(this._currentTip))  ?
      html `
        <dw-tooltip trigger="manual" .forEl="${this._currentTip.forEle}" .extraOptions="${this.__getExtraOptions()}" .offset=${this.__getOffset()} theme="blue" .sticky=${this._currentTip.sticky || true} .placement=${this.__getPlacement()}>
          <div class="title" ?hidden=${!this.__hasCurrentTipTitle()}>${i18next.t(`tip:${this._currentTip.name}.title`)}</div>
          <div class="sub-title" ?hidden=${!this.__hasCurrentTipSubTitle()}>${i18next.t(`tip:${this._currentTip.name}.subTitle`)}</div>
          <div class="message" ?has-actions-button=${this.__hasActionsButton()} ?has-image=${this.__hasCurrentTipImage()} ?hidden=${!this.__hasCurrentTipMessage()}>${unsafeHTML(i18next.t(`tip:${this._currentTip.name}.message`))}</div>
          <div class="image" ?mobile-layout=${!this.wideLayout} ?has-actions-button=${this.__hasActionsButton()} ?hidden=${!this.__hasCurrentTipImage()}>
            <img ?hidden=${!this.__hasCurrentTipImage()} src="${this.__hasCurrentTipImage() ? i18next.t(`tip:${this._currentTip.name}.image.${this.wideLayout ? 'desktop': 'mobile'}`): ''}" />
          </div>
          <div class="actions"
            ?only-primary-action=${this.__hasCurrentTipPrimaryAction() && !this.__hasCurrentTipSecondaryAction()}
            ?primary-and-secondary-action=${this.__hasCurrentTipPrimaryAction() && this.__hasCurrentTipSecondaryAction()}
            ?new-style=${this._currentTip && this._currentTip.newButtonStyle || false}
            ?center-button-style=${this._currentTip && this._currentTip.centerButtonStyle || false}
            ?hidden=${!this.__hasActionsButton()}>
            ${this._currentTip && this._currentTip.newButtonStyle ? html`
              ${this.__hasCurrentTipSecondaryAction() ? html`
                <kerika-button class="secondary-button" label=${i18next.t(`tip:${this._currentTip.name}.buttons.secondary`)}></kerika-button>
              `: ''}
              ${this.__hasCurrentTipPrimaryAction() ? html`
                <kerika-button class="primary-button" label=${i18next.t(`tip:${this._currentTip.name}.buttons.primary`)}></kerika-button>
              `: ''}
            `: html`
              ${this.__hasCurrentTipPrimaryAction() ? html`
                <a class="primary-button">${i18next.t(`tip:${this._currentTip.name}.buttons.primary`)}</a>
              `: ''}
            `}

          </div>
        </dw-tooltip>
      ` :
      ''
    }
    `;
  }

  updated(changedProps) {
    super.updated && super.updated(changedProps);
    if(changedProps.has('_currentTip')) {
      if(this._isSwitchTip(this._currentTip)) {
        this._tippyInstance && this._tippyInstance.setContent && this._tippyInstance.setContent(this._tooltipEl.innerHTML);
      }

      const popperEl = this._tippyInstance && this._tippyInstance.popper;
      const tippyBoxEl = popperEl && popperEl.querySelector('.tippy-box');
      if(tippyBoxEl) {
        tippyBoxEl.setAttribute('whole-tip-clickable', this._isWholeTipClickable());
      }
    }

    if(changedProps.has('hideTip')) {
      const popperEl = this._tippyInstance && this._tippyInstance.popper;
      if(popperEl) {
        if(this.hideTip) {
          popperEl.style.zIndex = '-1';
        } else {
          const options = this.__getExtraOptions();
          popperEl.style.zIndex = options && options.zIndex || '99';
        }
      }
    }

    if(changedProps.has('language')) {
      if(this._tooltipEl && this._tippyInstance) {
        this._tippyInstance.setContent && this._tippyInstance.setContent(this._tooltipEl.innerHTML);
      }
    }
  }

  get _tooltipEl() {
    return this.renderRoot.querySelector('dw-tooltip');;
  }

  get _tippyInstance() {
    return this._tooltipEl && this._tooltipEl._tippyInstance || null;
  }

  /**
   * Invoked when state is changed
   * @param {Obhet} state - Redux state
   */
  stateChanged(state) {
    this._isLoaded = knownFeatures.selectors.isLoaded(state);
    this._impersonatedUser = auth.selectors.impersonatedUser(state);
  }

  __getOffset() {
    return this._currentTip && this._currentTip.offset || [0, 12];
  }

  __getExtraOptions() {
    const currenTipName = this._currentTip && this._currentTip.name;
    const arrow = this._currentTip && this._currentTip.defaultEl ? false:  true;
    const defaultzIndex = get(config, `${currenTipName}.for`, 'PAGE') === 'PAGE' ? 99: 10000;
    const tipzIndex = get(config, `${currenTipName}.zIndex`, defaultzIndex);
    const extraOptions = merge({}, this._extraOptions, { arrow }, {zIndex: tipzIndex});
    return extraOptions;
  }

  __getPlacement() {
    return this._currentTip && this._currentTip.placement || 'bottom';
  }

  __hasCurrentTipTitle() {
    return i18next.exists(`tip:${this._currentTip.name}.title`);
  }

  __hasCurrentTipImage() {
    return i18next.exists(`tip:${this._currentTip.name}.image.${this.wideLayout ? 'desktop': 'mobile'}`);
  }

  __hasCurrentTipSubTitle() {
    return i18next.exists(`tip:${this._currentTip.name}.subTitle`);
  }

  __hasCurrentTipMessage() {
    return i18next.exists(`tip:${this._currentTip.name}.message`);
  }

  __hasCurrentTipPrimaryAction() {
    return i18next.exists(`tip:${this._currentTip.name}.buttons.primary`);
  }

  __hasCurrentTipSecondaryAction() {
    return i18next.exists(`tip:${this._currentTip.name}.buttons.secondary`);
  }

  __hasActionsButton() {
    return this.__hasCurrentTipPrimaryAction() || this.__hasCurrentTipSecondaryAction();
  }

  __showTipOnWindowEvent(event) {
    const data = get(event, 'detail', {});
    if(data && data.name) {
      this.show(data);
    }
  }

  __hideTipOnWindowEvent(event) {
    const name = get(event, 'detail.name');
    const isAcknowledged = get(event, 'detail.isAcknowledged', false);
    const skipMarkAsRead = get(event, 'detail.skipMarkAsRead', false);
    const currentTipName = this._currentTip && this._currentTip.name;
    const tipConfig = config[name] || {};
    if(tipConfig.for !== this.for) {
      return;
    }

    if(name && currentTipName === name) {
      this._hideTip(isAcknowledged, skipMarkAsRead);
    }

    this._pendingTips = this._pendingTips.filter((tip) => {
      return tip.name !== name;
    });
  }

  /**
   * Display high priority tip from pending tip and reset pending tip data
   *  - It removes known features tip data
   *  - Then find out first high priority tip and display it
   *  - Reset pending tip
   */
  _processPendingTip() {
    //Return if there is no pending tip.
    if(isEmpty(this._pendingTips)) {
      return;
    }

    //Remove known feature tip from pending tip list
    let _pendingTips = this._pendingTips.filter((tip) => {
      const isKnown = knownFeatures.selectors.isKnown({ state: store.getState(), name: tip.name });
      const onceOpened = knownFeatures.selectors.onceOpened(store.getState(), tip.name);
      if(onceOpened) {
        return false;
      }
      return isKnown === undefined ? true : !isKnown;
    });

    //Find out first high priority tip
    let _tip = !isEmpty(_pendingTips) && _pendingTips.reduce((tip1, tip2) => {
      if(config[tip1.name]['priority'] <= config[tip2.name]['priority']) {
        return tip1;
      }
      return tip2;
    });

    //If there is not tip to display then return
    if(!_tip) {
      this._pendingTips = [];
      return;
    }

    //To destroy existing tip dom, First reset tip data and then set real tip data after timeout
    if(!this._isSwitchTip(_tip)) {
      this._currentTip = {};
    }

    window.setTimeout(() => {
      this._currentTip = { ..._tip };
      store.dispatch(knownFeatures.actions.changeOpenedOnce(this._currentTip.name, true));
      amplitude.logEvent('tip presented', {name: this._currentTip.name});
      this.__tipPresentedStartTime = new Date().getTime();
      this._pendingTips = [];
    }, 0);
  }

  /**
   * Hide currently opened tip and reset its data
   */
  _hideTip(acknowledgedOnHide = false, skipMarkAsRead = false) {
    //Return if there is no opened tip
    if (isEmpty(this._currentTip)) {
      return;
    }

    if(skipMarkAsRead) {
      this._resetTipData();
      return;
    }

    const currentTipName = this._currentTip && this._currentTip.name;
    const duration = this.__getDurationTime();
    const neverAcknowledged = get(config, `${currentTipName}.neverAcknowledged`, false);

    let isAcknowledged = false;
    //Mark feature is known to user when user click on primary button.
    if(this.__invokedPrimaryAction || this.__invokedSecondaryAction) {
      isAcknowledged = true;
      if(this.__invokedPrimaryAction && this._currentTip.primaryCallback && typeof this._currentTip.primaryCallback === 'function') {
        this._currentTip.primaryCallback();
      }

      if(this.__invokedSecondaryAction && this._currentTip.secondaryCallback && typeof this._currentTip.secondaryCallback === 'function') {
        this._currentTip.secondaryCallback();
      }
    }

    //Mark feature is known on dismiss
    if(!isAcknowledged && this._isMarkKnownOnDismiss()) {
      isAcknowledged = true;
    }

    if(this.__clickOnTip && this._isWholeTipClickable()) {
      isAcknowledged = true;
      if(this._currentTip && this._currentTip.wholeTipClickCallback && typeof this._currentTip.wholeTipClickCallback === 'function') {
        this._currentTip.wholeTipClickCallback();
      }
    }

    if(currentTipName === 'SEARCH_REF_NO_TIP') {
      store.dispatch(search.actions.updateRefNoTipLastSeen());
    }

    if(acknowledgedOnHide && !isAcknowledged) {
      isAcknowledged = true;
    }

    if(!isAcknowledged || neverAcknowledged) {
      amplitude.logEvent(this.__invokedReferenceClick ? 'tip followed' : 'tip dismissed', {name: currentTipName, duration});
    } else {
      if(this._isMarkKnownOnPrimaryButton()) {
        if(this.__invokedPrimaryAction) {
          store.dispatch(knownFeatures.actions.markAsKnown(currentTipName, { duration, acknowledged: 'MANUAL', skipped: this._isSkipButtonClicked() }));
        }
      } else {
        store.dispatch(knownFeatures.actions.markAsKnown(currentTipName, { duration, acknowledged: 'MANUAL', skipped: this._isSkipButtonClicked() }));
      }
    }

    //Reset currrent tip to hide tip
    this._resetTipData();
  }

  _resetTipData() {
    this._currentTip = {};
    this.__invokedSecondaryAction = false;
    this.__invokedPrimaryAction = false;
    this.__invokedOutsideClick = false;
    this.__invokedReferenceClick = false;
    this.__clickOnTip = false;
    this.__tipPresentedStartTime = undefined;
  }

  __getDurationTime() {
    const currentTime = new Date().getTime();
    const startTime = this.__tipPresentedStartTime || currentTime;
    return Math.floor((currentTime - startTime) / 1000);
  }

  /**
   * @returns `true` when user click on skip button.
   */
  _isSkipButtonClicked() {
    if(!this._currentTip || !this._currentTip.name) {
      return false;
    }

    if(!this.__invokedPrimaryAction && !this.__invokedSecondaryAction) {
      return false;
    }

    if(this.__invokedPrimaryAction) {
      return get(config, `${this._currentTip.name}.skipButton`) === 'primary';
    }

    return get(config, `${this._currentTip.name}.skipButton`) === 'secondary';
  }

  /**
   * @returns `true` when tip is closed on outside click.
   */
  _isCancelOnOutsideClick() {
    if(!this._currentTip || !this._currentTip.name) {
      return false;
    }
    return !get(config, `${this._currentTip.name}.noCancelOnOutside`, false);
  }

  /**
   * @returns `true` when tip is switchable. only current tip content change and no animation.
   */
  _isSwitchTip(tip) {
    tip = tip || this._currentTip;
    if(!tip || !tip.name) {
      return false;
    }
    return get(config, `${tip.name}.switch`, false);
  }

  /**
   * @returns `true` when whole tip is clickable.
   */
  _isWholeTipClickable(tip) {
    tip = tip || this._currentTip;
    if(!tip || !tip.name) {
      return false;
    }
    return get(config, `${tip.name}.wholeTipClickable`, false);
  }

  /**
   * @returns `true` tip known on dismiss.
   */
  _isMarkKnownOnDismiss(tip) {
    tip = tip || this._currentTip;
    if(!tip || !tip.name) {
      return false;
    }
    return get(config, `${tip.name}.knownOnDismiss`, false);
  }

  /**
   * @returns `true` tip known on primary-button.
   */
  _isMarkKnownOnPrimaryButton(tip) {
    tip = tip || this._currentTip;
    if (!tip || !tip.name) {
      return false;
    }
    return get(config, `${tip.name}.knownOnPrimaryButton`, false);
  }

  /**
   * Show tip on passed component if user had not achieve this feature
   * @param {*} name - Name of feature
   * @param {*} forEle - Element where display tip if user had not achieved this feature.
   */
  show(data) {
    if(!data || !data.name) {
      throw new Error('Mandatory parameters tip name is not passed');
    }

    const newTipConfig = config[data.name] || {};
    if(newTipConfig.for !== this.for) {
      return;
    }

    if(!data.forEle) {
      if(this._isSwitchTip(data) && this._currentTip && this._currentTip.forEle){
        data.forEle = this._currentTip.forEle;
      } else {
        let el = document.body.querySelector('#defaultTipForEl');
        if(!el) {
          el = document.createElement('div');
          el.id = "defaultTipForEl";
          el.style.position = 'fixed';
          el.style.bottom = '16px';
          el.style.left = '16px';
          document.body.appendChild(el);
        }
        data.forEle = el;
        data.placement = 'bottom-start';
        data.defaultEl = true;
        data.offset = [0, 0];
      }
    }

    //If tip is opened and its priority is same as new tip or higher then return without display new tip
    if(!isEmpty(this._currentTip) && config[this._currentTip.name]['priority'] <= config[data.name]['priority'] ) {
      return;
    }

    let _pendingTips = filter(this._pendingTips, (tip) => {
      return tip.name !== data.name;
    });
    _pendingTips.push(data);

    //Add tip into pending list
    this._pendingTips = _pendingTips;

    //If known features are not loaded yet
    if (!this._isLoaded) {
      return;
    }

    //If user is impersonated user.
    if(this._impersonatedUser) {
      return;
    }

    this._processPendingTip();
  }

  /**
   * Hide currently opened tip
   */
  hide() {
    this._hideTip();
  }

  disconnectedCallback() {
    //Listen on window events
    window.removeEventListener('show-tip',this.__showTipOnWindowEvent);
    window.removeEventListener('hide-tip', this.__hideTipOnWindowEvent);
    super.disconnectedCallback && super.disconnectedCallback();
  }
}

window.customElements.define('feature-tooltip', FeatureTooltip);