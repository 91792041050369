/**
 * Key = Name of the Feature.
 * Value = Firebase path, priority
 *  Firebase Path: Path where  this feature's data (boolean value) is stored.
 *    This path is relative to the base  path of the known-features data for a user.
 *
 *    Note:: You may notice that some paths are camel-case and some are dash separated.
 *    Reason: For the old tips (which are shown in the current desktop app as well) are
 *    not changed. But, for the new tips, we always use dash-separated pattern.
 *
 *  Priority: priorities for all known-featuers.
 *    Used to display high priority tip from pending tip first.
 *
 *  skipMarkAsRead: When it's true, does not mark as read ever.
 */
export const config = {
  USE_CASE_BOARD_COPY_READY: {
    priority: 1,
    for: 'PAGE',
    knownOnDismiss: true,
  },
  GETTING_STARTED_BOARD_READY: {
    priority: 2,
    for: 'PAGE',
    knownOnDismiss: true,
  },
  TASKBOARD_PARTICIPANT: {
    priority: 3,
    for: 'PAGE',
    skipButton: "secondary"
  },
  TASKBOARD_NON_PARTICIPANT: {
    priority: 4,
    for: 'PAGE',
    skipButton: "secondary"
  },
  WHITEBOARD_PARTICIPANT: {
    priority: 5,
    for: 'PAGE',
    skipButton: "secondary"
  },
  CANVAS_PARTICIPANT: {
    priority: 6,
    for: 'PAGE',
    skipButton: "secondary"
  },
  CANVAS_VISITOR: {
    priority: 7,
    for: 'PAGE',
    skipButton: "secondary"
  },
  WHITEBOARD_NON_PARTICIPANT: {
    priority: 8,
    for: 'PAGE',
    skipButton: "secondary"
  },
  CANVAS_NON_PARTICIPANT: {
    priority: 9,
    for: 'PAGE',
    skipButton: "secondary"
  },
  ADD_NEW_CARD: {
    priority: 10,
    for: 'PAGE'
  },
  OPEN_CARD_1: {
    priority: 11,
    for: 'PAGE'
  },
  OPEN_CARD_2: {
    priority: 12,
    for: 'PAGE'
  },
  LOOK_AROUND_OPEN_CARD: {
    priority: 13,
    for: 'PAGE'
  },
  MAKE_COPY_OR_BROWSE_DESKTOP: {
    priority: 14,
    for: 'PAGE',
    knownOnDismiss: true,
    zIndex: 102,
  },
  MAKE_COPY_OR_BROWSE_MOBILE: {
    priority: 15,
    for: 'PAGE',
    knownOnDismiss: true,
    zIndex: 102,
  },
  CARD_DND: {
    priority: 16,
    knownOnDismiss: true,
    for: 'PAGE'
  },
  INVITED_TRELLO_TEAM_DESKTOP_TRIAL: {
    priority: 17,
    for: 'PAGE'
  },
  INVITED_TRELLO_TEAM_MOBILE_TRIAL: {
    priority: 18,
    for: 'PAGE'
  },
  INVITED_TRELLO_TEAM_DESKTOP: {
    priority: 19,
    for: 'PAGE'
  },
  INVITED_TRELLO_TEAM_MOBILE: {
    priority: 20,
    for: 'PAGE'
  },
  SHARE_BOARD: {
    priority: 21,
    for: 'PAGE'
  },
  ASSIGNED_TO_ME_HIGHLIGHTS: {
    priority: 22,
    for: 'PAGE'
  },
  ADD_CARD_DETAILS: {
    priority: 23,
    for: 'DIALOG'
  },
  ADD_NEW_TASK: {
    priority: 24,
    for: 'DIALOG'
  },
  ADD_NEW_ATTACHMENT: {
    priority: 25,
    for: 'DIALOG'
  },
  FIRST_ATTACHMENT_AO_NO_TEAM_PRIVATE: {
    priority: 26,
    for: 'DIALOG',
    knownOnDismiss: true
  },
  FIRST_ATTACHMENT_AO_NO_TEAM_NON_PRIVATE: {
    priority: 27,
    for: 'DIALOG',
    knownOnDismiss: true
  },
  FIRST_ATTACHMENT_AO_WITH_TEAM: {
    priority: 28,
    for: 'DIALOG',
    knownOnDismiss: true
  },
  FIRST_ATTACHMENT_TM: {
    priority: 29,
    for: 'DIALOG',
    knownOnDismiss: true
  },
  NEW_MEMBER_ADDED: {
    priority: 30,
    for: 'DIALOG',
    knownOnDismiss: true
  },
  MEMBER_REMOVED: {
    priority: 31,
    for: 'DIALOG',
    knownOnDismiss: true
  },
  MEMBER_ROLE_UPGRADE: {
    priority: 32,
    for: 'DIALOG',
    knownOnDismiss: true
  },
  MEMBER_ROLE_DOWNGRADE: {
    priority: 33,
    for: 'DIALOG',
    knownOnDismiss: true
  },
  BOARDS_EXPLORER: {
    priority: 34,
    for: 'PAGE',
    skipButton: "secondary"
  },
  TEMPLATES_EXPLORER: {
    priority: 35,
    for: 'PAGE',
    skipButton: "secondary"
  },
  DASHBOARD_EXPLORER: {
    priority: 36,
    for: 'PAGE',
    skipButton: "secondary"
  },
  BOARD_SWITCHER: {
    priority: 37,
    for: 'PAGE',
    knownOnDismiss: true
  },
  FOR_ME_FILTER: {
    priority: 38,
    for: 'PAGE',
    knownOnDismiss: true
  },
  SEARCH_REF_NO_TIP: {
    priority: 39,
    for: 'PAGE',
    zIndex: 100
  },
  ACCOUNT_PREFERENCES: {
    priority: 40,
    for: 'PAGE',
    knownOnPrimaryButton: true
  },
  MANAGE_ACCOUNTS: {
    priority: 41,
    for: 'PAGE',
    knownOnPrimaryButton: true
  },
  INNER_CANVAS: {
    priority: 42,
    for: 'PAGE',
    knownOnDismiss: true
  }
};
