import { css } from "lit-element";

export const theme = css`
  :host {
    --primary-text-color: rgba(0, 0, 0, .87);
    --primary-background-color: #fff;
    --grey-background-color: #808080;
    --secondary-text-color: rgba(0, 0, 0, .54);
    --disabled-text-color: rgba(0, 0, 0, .38);

    --divider-color: rgba(0, 0, 0, .10);
    --separator-color: rgba(0, 0, 0, .20);
    --card-detail-button-outline-color: rgba(0, 0, 0, .20);

    --red-color: #ed1c24;
    --light-green-color: #7CB342;
    --orange-color: #F8981D;
    --dark-orange-color: #AA6D0F;
    --blue-color: #2096F3;
    --column-title-divider-color: rgba(0, 0, 0, 0.12);

    --user-avatar-background-color: #e8e4dd;
    --error-color: var(--red-color);
    --success-color: var(--light-green-color);
    --warning-color: var(--orange-color);
    --warning-background-color: #faefee;
    --yellow-background-color: #FFA;

    /**
    * Icon color
    */
    --icon-color: rgba(0, 0, 0, .54);
    --disable-icon-color: rgba(0, 0, 0, .38);
    --white-icon-color: #fff;
    --magenta-icon-color: #c11e5c;

    --primary-color: #c11e5c;
    --secondary-color: var(--light-green-color);
    --light-primary-color: #c11e5c;
    --dark-primary-color: #c11e5c;

    --dark-theme-background-color: #242935;
    --dark-theme-secondary-color: rgba(255, 255, 255, .70);
    --dark-theme-disabled-color: rgba(255, 255, 255, .50);
    --dark-theme-divider-color: rgba(255, 255, 255, .12);
    --dark-primary-text-color: #fff;

    --app-header-background-color: #242935;
    --app-header-separator-color: rgba(255, 255, 255, 0.3);
    /* Text color on Black page header */
    --page-header-title-color: #fff;
    --page-header-title-overline-color: rgba(255, 255, 255, .75);
    --page-header-background-color: #fff;
    --page-background-color: #D8E3DF;

    /**
    * MDC theme variables
    */
    --mdc-theme-primary: var(--primary-color);
    --mdc-theme-secondary: var(--blue-color);
    --mdc-theme-error: var(--red-color);
    --mdc-theme-surface: #fff;
    --mdc-theme-on-surface-on-light: #000;
    --mdc-theme-on-surface-on-dark: #fff;
    --mdc-theme-on-surface: var(--mdc-theme-on-surface-on-light);

    --mdc-theme-on-primary: #fff;
    --mdc-theme-on-secondary: #fff;
    --mdc-theme-on-error: #fff;
    --mdc-theme-text-primary: rgba(0, 0, 0, 0.87);
    --mdc-theme-text-disabled: rgba(0, 0, 0, 0.38);
    --dw-disabled-background-color: rgba(0, 0, 0, 0.12);
    --mdc-theme-text-hint: rgba(0, 0, 0, 0.38);

    --dw-icon-color-active-on-light: #c11e5c;
    --dw-icon-color-on-light: rgba(0, 0, 0, .54);
    --dw-icon-color-disabled-on-light: rgba(0, 0, 0, .38);

    --dw-icon-color-active-on-dark: rgba(255, 255, 255, 1);
    --dw-icon-color-on-dark: rgba(255, 255, 255, 1);
    --dw-icon-color-disabled-on-dark: rgba(255, 255, 255, .50);
    --mdc-theme-text-primary-on-dark: rgba(255, 255, 255, 1);

    --dw-icon-color: var(--dw-icon-color-on-light);
    --dw-icon-color-active: var(--dw-icon-color-active-on-light);
    --dw-icon-color-disabled: var(--dw-icon-color-disabled-on-light);

    /**
    * wide and narrow layout header height.
    */
    --single-header-height-narrow: 56px;
    --multi-header-height-narrow: 104px;
    --app-header-height-narrow: 56px;
    --page-header-height-narrow: 48px;
    --card-detail-tab-header-height: 48px;

    --single-header-height-wide: 44px;
    --multi-header-height-wide: 88px;
    --app-header-height-wide: 44px;
    --page-header-height-wide: 44px;
    --bottom-toolbar-height: 56px;
    --popover-header-height: 56px;

    --scrollbar-track-color: #EFEFEF;
    --scrollbar-thumb-color: #CFCFCF;

    --overdue-wip-limit-color: #ed1c24;

    /**
    * Card status color
    */
    --card-status-ready-color: var(--light-green-color);
    --card-status-on-hold-color: var(--dark-orange-color);
    --card-status-needs-review-color: var(--light-green-color);
    --card-status-needs-rework-color: var(--dark-orange-color);
    --card-status-in-progress-color: var(--light-green-color);
    --card-status-block-color: var(--red-color);
    --card-status-done-color: var(--light-green-color);
    --card-status-critical-color: var(--red-color);

    /**
    * Shadow color
    */
    --shadow-color: rgba(0,0,0,0.4);

    --tag-icon-border-color: rgba(0, 0, 0, 0.05);
    --tag-icon-button-hover-background-color: rgba(0, 0, 0, 0.04);

    --kerika-video-dialog-background: rgba(0, 0, 0, 0.87);

    /**
    * Card label color
    */
    --card-label-color: var(--dark-primary-text-color);

    /**
    * Unread highlight color.
    */
    --kerika-unread-updated: var(--orange-color);
    --kerika-unread-new: var(--blue-color);
    --kerika-unread-overdue: var(--red-color);

    --kerika-highlight-color: var(--orange-color);
    --chat-unread-color: rgba(248, 152, 29, 0.16);

    /**
    * Card priority icon color
    */
    --card-priority-icon-color: var(--error-color);

    /**
    * Card assignment color
    */
    --card-assignment-light-color: var(--secondary-text-color);

    /**
    * Card user avatar color
    */
    --user-avatar-dropdown--background-color: #f9f9f9;

    --confirm-popup-background-color: #f9f9f9;

    --confirm-background-color: #fdf6eb;

    /**
    * Card due date color
    */
    --card-due-date-hover-color: rgb(144, 32, 96);
    --due-date-color: var(--light-green-color);
    --overdue-due-date-color: var(--red-color);
    --board-card-selection-bar-background-color: rgba(0, 0, 0, 0.04);

    /**
     * Done trash column placeholder color.
     * see: https://www.figma.com/proto/1JccigEM6AHjm3M6HBRE1Q/Kerika?node-id=823%3A27864&scaling=min-zoom&page-id=204%3A153
     */
    --done-trash-column-placeholder-border-color: rgba(0, 0, 0, 0.38);
    --done-trash-column-placeholder-background-color: #F0F0F0;

    /**
    * Selected card boarder color
    */
    --selected-card-border-color: #c11e5c;

    /**
    * Selected item boarder color
    */
    --selected-item-border-color: #c11e5c;


    /**
    * Sub title color
    */
    --sub-title-color: var(--blue-color);

    /**
    * Dialog heder title color.
    */
    --dialog-header-title-color: var(--blue-color);

    /**
    * Checked icon color.
    */
    --check-icon-color: var(--light-green-color);

    /**
    * Overlay color
    */
    --overlay-color: rgba(0,0,0,0.4);

    /**
    * card detail dialog max width
    */
    --card-detail-dialog-max-width: 768px;

    /**
    * card detail empty state message color.
    */
    --card-detail-empty-state-color: rgb(170, 109, 15);
    /**
    * task check-box fill color.
    */
    --grey-checkbox-fill-color: #808080;

    /**
    * Chat theme
    */
    --owned-message-background-color: #e4f2fe;
    --others-message-background-color: #f6f6f6;
    --deleted-message-background-color: #f9f9f9;

    --danger-text-color: var(--red-color);

    /**
    * Pagination bullet colors
    */
    --pagination-bullet-background-color: rgba(0,0,0,.26);
    --pagination-active-bullet-background-color: var(--blue-color);

    --list-item-focused-background-color: rgba(0, 0, 0, 0.04);
    --list-item-hover-background-color: rgba(0, 0, 0, 0.04);
    --list-item-edit-background-color: rgba(0, 0, 0, 0.04);
    --list-item-focused-hover-background-color: rgba(0, 0, 0, 0.04);
    --list-item-edit-hover-background-color: rgba(0, 0, 0, 0.04);
    --list-item-delete-confirmation-background-color: rgba(0, 0, 0, 0.04);
    --list-item-deleted-background-color: rgba(0, 0, 0, 0.04);
    --list-item-primary-hover-background-color: rgba(193, 30, 92, 0.04);

    --edit-opaque-background-color: #f5f5f5;

    --info-message-background-color: rgba(0, 0, 0, 0.04);

    /**
    * Bottom navigation
    */
    --kerika-bottom-navigation-height: 56px;

    /**
    * Drag and drop colors.
    */
    --dw-sortable-list-palceholder-background-color: rgba(0,0,0,.12);
    --dw-sortable-list-drag-handler-background-color: #fff;

    --kerika-headline-color-green: #7CB242;
    --kerika-page-background-grey: #ecf0f7;
    --kerika-logo-color: #EC145B;

    /* Search highlight color */
    --list-item-highlight-background-color: #d7e8c6;

    /* Typography */

    /* headline 5 */
    --headline5-font-size: 24px;
    --headline5-font-weight: 400;
    --headline5-letter-spacing: 0.25px;
    --headline5-line-height: 24px;

    /* headline 6 */
    --headline6-font-size: 18px;
    --headline6-font-weight: 400;
    --headline6-letter-spacing: 0.25px;
    --headline6-line-height: normal;

    /* body 1 */
    --body1-font-size: 16px;
    --body1-font-weight: 400;
    --body1-letter-spacing: 0.5px;
    --body1-line-height: 18px;

    /* subtitle 1 */
    --subtitle1-font-size: var(--body1-font-size);
    --subtitle1-font-weight: var(--body1-font-weight);
    --subtitle1-letter-spacing: var(--body1-letter-spacing);
    --subtitle1-line-height: var(--body1-line-height);
    --subtitle1-text-transform: inherit;

    /* subtitle 2 */
    --subtitle2-font-size: 14px;
    --subtitle2-font-weight: 500;
    --subtitle2-letter-spacing: 0.1px;
    --subtitle2-line-height: normal;

    /* body 2 */
    --body2-font-size: 14px;
    --body2-font-weight: 400;
    --body2-letter-spacing: 0.25px;
    --body2-line-height: 18px;

    /* button */
    --button-font-size: 14px;
    --button-font-weight: 400;
    --button-letter-spacing: 1.25px;
    --button-line-height: 14px;

    /* caption */
    --caption-font-size: 12px;
    --caption-font-weight: 400;
    --caption-letter-spacing: 0.25px;
    --caption-line-height: normal;

    /* overline */
    --overline-font-size: 10px;
    --overline-font-weight: 400;
    --overline-letter-spacing: 1.5px;
    --overline-line-height: 10px;
  }

  :host([layout="narrow"]) {
    --subtitle1-font-size: 16px;
    --subtitle1-font-weight: 400;
    --subtitle1-letter-spacing: 0.15px;
    --subtitle1-line-height: 18px;
    --subtitle1-text-transform: inherit;
  }

  :host([lng="hi"]), :host([lang="hi"]), :host([language="hi"]),
  :host([lng="gu"]), :host([lang="gu"]), :host([language="gu"]) {
    --headline5-line-height: normal;
    --subtitle1-line-height: normal;
    --button-line-height: normal;
    --body1-line-height: normal;
    --body2-line-height: normal;
    --overline-line-height: normal;
  }
}`;


export default theme;