import {
  createStore,
  compose,
  applyMiddleware,
  combineReducers
} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga'
import { lazyReducerEnhancer } from 'pwa-helpers/lazy-reducer-enhancer';
import { persistEnhancer } from '@dw/pwa-helpers/redux-persist-enhancer';

import app from './redux/app/reducers.js';
import appSaga from './redux/app/saga.js';
import deviceSaga from './redux/device/saga.js';
import deviceReducer from './redux/device/reducers.js';
import boardTranslationsReducer from './redux/board-translations/reducers.js';
import boardTranslationsSaga from './redux/board-translations/saga.js';
import { boardExplorer } from './redux/board-explorer/reducers';
import { fileStore, fileStorePreferredStoreSelection } from './redux/file-store/reducers.js';
import { boardSummary } from './redux/board-summary/reducers.js';
import { signupReducer } from './redux/signup/reducers.js';
import { forgotPassword } from './redux/forgot-password/reducers.js';
import * as board from './redux/board';
import { fbReducer } from '@dw/firebase-redux';
import  * as router from './redux/router';
import * as auth from './redux/auth';
import * as clipboard from './redux/clipboard';
import * as zoomOutView from './redux/zoomout-view';
export const sagaMiddleware = createSagaMiddleware();
import signupSaga from './redux/signup/saga.js';
import moduleLoader from './redux/module-loader/saga';
import boardSaga from './redux/board/saga';
import loginSaga from './redux/auth/saga.js';
import featureSaga from './redux/feature/saga.js';
import forgotPasswordSaga from './redux/forgot-password/saga.js';
import accessibleAccountsSaga from './redux/auth/accessible-accounts/saga';
import lastVisitedPageSaga from './redux/router/last-visited-page/saga.js';
import fileStoreSaga from './redux/file-store/saga.js';
import boardSummarySaga from './redux/board-summary/saga.js';
import messagesSaga from './redux/messages/saga.js';
import userSaga from './redux/user/saga.js';
import * as lastMoveCards from './redux/last-move-cards';
import { getCookieVal } from './utils.js';

// multiple language reducer and saga
import multipleLanguagesReducer  from './redux/multiple-language/reducers.js';
import multipleLanguageSaga from './redux/multiple-language/saga.js';

const persistOptions = [{ path: 'board-highlights', sharedBetweenTabs: true }, { path: 'clipboard', sharedBetweenTabs: true }, { path: 'zoomout-view', sharedBetweenTabs: true }, {path: 'last-move-cards', sharedBetweenTabs: true}, {path: 'task', sharedBetweenTabs: true}];

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
const devCompose = getCookieVal('redux_devtools_access') && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: true
      }) : compose;


// Initializes the Redux store with a lazyReducerEnhancer (so that you can
// lazily add reducers after the store has been created) and redux-thunk (so
// that you can dispatch async actions). See the "Redux and state management"
// section of the wiki for more details:
// https://pwa-starter-kit.polymer-project.org/redux-and-state-management
export const store = createStore(
  state => state,
  devCompose(
    lazyReducerEnhancer(combineReducers),
    applyMiddleware(thunk, sagaMiddleware),
    persistEnhancer(persistOptions)
  )
);

sagaMiddleware.run(appSaga);
sagaMiddleware.run(deviceSaga);
sagaMiddleware.run(moduleLoader);
sagaMiddleware.run(boardTranslationsSaga);
sagaMiddleware.run(boardSaga);
sagaMiddleware.run(accessibleAccountsSaga);
sagaMiddleware.run(lastVisitedPageSaga);
sagaMiddleware.run(loginSaga);
sagaMiddleware.run(featureSaga);
sagaMiddleware.run(fileStoreSaga);
sagaMiddleware.run(boardSummarySaga);
sagaMiddleware.run(signupSaga);
sagaMiddleware.run(forgotPasswordSaga);
sagaMiddleware.run(clipboard.saga.clipboardSaga);
sagaMiddleware.run(messagesSaga);
sagaMiddleware.run(lastMoveCards.saga.lastMoveCardsSaga);
sagaMiddleware.run(multipleLanguageSaga);
sagaMiddleware.run(userSaga);

// Initially loaded reducers.
store.addReducers({
  app,
  auth: auth.reducers.authReducer,
  router: router.reducers.routerReducer,
  'board-translations': boardTranslationsReducer,
  'device': deviceReducer,
  'login-and-signup': auth.reducers.loginAndSignupReducer,
  firebase: fbReducer,
  board: board.reducers.boardReducer,
  'board-team': board.reducers.boardTeamReducer,
  'board-explorer': boardExplorer,
  'multiple-language': multipleLanguagesReducer,
  'file-store': fileStore,
  'file-store-preferred-store-selection': fileStorePreferredStoreSelection,
  'board-summary': boardSummary,
  'signup': signupReducer,
  'forgot-password': forgotPassword,
  clipboard: clipboard.reducers.clipboardReducer,
  'zoomout-view': zoomOutView.reducers.zoomOutViewReducer,
  'last-move-cards': lastMoveCards.reducers.lastMoveCardsReducer,
  'board-highlights': (state = {}) => state, // As there is an issue in redux's `combineReducer`,
                                            // when any slice is being persisted in localStorage & loaded lazily when needed, it should be added initially.
});
