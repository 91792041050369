import * as actions from './actions.js';
import * as signup from '../signup';
import { ReduxUtils } from '@dw/pwa-helpers/index.js';

const INITIAL_STATE = {};

export const importBoard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.AUTHENTICATE:
      state = ReduxUtils.replace(state, 'authentication', {error: undefined, status: 'IN_PROGRESS'});
      state = ReduxUtils.replace(state, 'import', undefined);
      return ReduxUtils.replace(state, 'data', undefined);
    case actions.AUTHENTICATE_FAILED:
      return ReduxUtils.replace(state, 'authentication', {error: action.error, status: 'FAILED'});
    case actions.AUTHENTICATE_SUCCESS:
      state = ReduxUtils.replace(state, 'import', undefined);
      state = ReduxUtils.replace(state, 'data', undefined);
      return ReduxUtils.replace(state, 'authentication', {error: undefined, status: 'SUCCESS'});
    case actions.LOAD_DETAILS_DONE:
      return ReduxUtils.replace(state, 'data', action.data);
    case actions.IMPORT_BOARDS:
      return ReduxUtils.replace(state, 'import', {error: undefined, status: 'IN_PROGRESS', boards: action.boards});
    case actions.IMPORT_BOARDS_FAILED:
      state = ReduxUtils.replace(state, 'import.error', action.error);
      return ReduxUtils.replace(state, 'import.status', 'FAILED');
    case actions.IMPORT_BOARDS_SUCCESS:
      state = ReduxUtils.replace(state, 'import.error', undefined);
      return ReduxUtils.replace(state, 'import.status', 'SUCCESS');
    case actions.UPLOAD_FILE: 
      state = ReduxUtils.replace(state, 'uploadFile.error', undefined);
      return ReduxUtils.replace(state, 'uploadFile.status', 'IN_PROGRESS');
    case actions.UPLOAD_FILE_SUCCESS: 
      state = ReduxUtils.replace(state, 'uploadFile.error', undefined);
      return ReduxUtils.replace(state, 'uploadFile.status', 'SUCCESS');
    case actions.UPLOAD_FILE_FAILED: 
      state = ReduxUtils.replace(state, 'uploadFile.error', action.error);
      return ReduxUtils.replace(state, 'uploadFile.status', 'FAILED');
    case actions.RESET_DATA:
    case signup.actions.PAGE_CLOSED:
      state = ReduxUtils.replace(state, 'authentication', undefined);
      state = ReduxUtils.replace(state, 'import', undefined);
      return ReduxUtils.replace(state, 'data', undefined);
    default:
      return state;
  }
};