import { css } from 'lit-element';
import typographyLiterals from './typography-literals';

export const tableSharedStyles = css`
  .table,
  :host(.table) {
    border: 1px solid var(--divider-color);
    border-top: none;
  }

  .table-header,
  :host(.table-header),
  .table-list-item,
  :host(.table-list-item) {
    position: relative;
    box-sizing: border-box;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${typographyLiterals.body2};
  }

  .table-header,
  :host(.table-header) {
    color: var(--mdc-theme-surface);
    height: 40px;
    background-color: var(--table-header-background-color, #757575);
    text-transform: uppercase;
  }

  .table-header > div,
  :host(.table-header) > div,
  .table-list-item > div,
  :host(.table-list-item) > div {
    padding: 0 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table-list-item,
  :host(.table-list-item) {
    height: 56px;
  }

  .table-list-item:not([touch-device]):hover,
  :host(.table-list-item:not([touch-device]):hover) {
    background-color: var(--list-item-hover-background-color);
  }

`