import URI from '@dw/urijs-esm';

export const usecaseBoardCopyingPageRouter = (data) => {
  return new Promise(function(resolve, reject) {
    if(data.path.startsWith('/use-case-board-copying')) {
      const params = new URI().query(true);
      data.page = {
        name: 'USE_CASE_BOARD_COPYING',
        params
      };
      resolve(data);
      return;
    }
    reject(data);
  });
}