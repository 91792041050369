import {
  CARD_SELECTION_UPDATE,
  CARD_SELECTION_CLEAR,
  BOARD_LOADED,
  UPDATE_BOARD_DRAG_STARTED,
  BOARD_TEAM_ADD_MEMBER,
  BOARD_TEAM_ADD_MEMBER_IN_PROGRESS,
  BOARD_TEAM_ADD_MEMBER_DONE,
  BOARD_TEAM_ADD_MEMBER_FAILED,
  BOARD_TEAM_CHANGE_MEMBER_ROLE,
	BOARD_TEAM_CHANGE_MEMBER_ROLE_DONE,
  BOARD_TEAM_CHANGE_MEMBER_ROLE_FAILED,
  BOARD_COPY,
  BOARD_COPY_DONE,
  BOARD_COPY_FAILED,
  LOAD_KNOWN_USERS_DONE,
  PAGE_CHANGED,
} from './actions';
import * as cardActions from '../card/actions';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import uniq from 'lodash-es/uniq';

const INITIAL_STATE = {
  loaded: {},
  selectedCards: [],
  dragStarted: false,
  requests: {},
  userUiLoaded: {},
  welcomeDetail: {},
  boardCopyInprogress: false,
};

export const boardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CARD_SELECTION_UPDATE:
      return {
        ...state,
        selectedCards: uniq(action.cardIds),
        selectedColumn: action.columnId,
      };

    case CARD_SELECTION_CLEAR:
      return {
        ...state,
        selectedCards: [],
        selectedColumn: null,
      };
    case PAGE_CHANGED:
      return ReduxUtils.replace(state, `loaded.${action.previousBoardId}`, false);
    case BOARD_LOADED:
      state = ReduxUtils.replace(state, `cardSummary.${action.boardId}`, action.cardSummary);
      return ReduxUtils.replace(state, `loaded.${action.boardId}`, true);
    case UPDATE_BOARD_DRAG_STARTED:
      state = ReduxUtils.replace(state, `cardDrag.start`, action.start);
      return ReduxUtils.replace(state, `cardDrag.cards`, action.cards);
    case BOARD_TEAM_ADD_MEMBER_IN_PROGRESS:
    case BOARD_TEAM_ADD_MEMBER_DONE:
    case BOARD_TEAM_ADD_MEMBER_FAILED:
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.requestId]: action,
        },
      };
    case cardActions.COPY:
      return ReduxUtils.replace(state, `requests.${action.columnId}.status`, 'IN_PROGRESS');
    case cardActions.COPY_SUCCESS:
      return ReduxUtils.replace(state, `requests.${action.columnId}.status`, 'SUCCESS');
    case BOARD_COPY:
      return ReduxUtils.replace(state, `boardCopyInprogress`, true);
    case BOARD_COPY_DONE:
    case BOARD_COPY_FAILED:
      return ReduxUtils.replace(state, `boardCopyInprogress`, false);
    case LOAD_KNOWN_USERS_DONE:
      return ReduxUtils.replace(state, `knownUsers`, action.knownUsers);
    default:
      return state;
  }
};

export const boardTeamReducer = (state = {}, action) => {
  let oState = {...state};
  switch (action.type) {
    case BOARD_TEAM_ADD_MEMBER:
    case BOARD_TEAM_ADD_MEMBER_IN_PROGRESS:
      oState = ReduxUtils.replace(oState, `lastAddedMembers.status`, 'IN_PROGRESS');
      oState = ReduxUtils.replace(oState, `lastAddedMembers.error`, undefined);
      oState = ReduxUtils.replace(oState, `lastAddedMembers.requestId`, action.requestId);
      oState = ReduxUtils.replace(oState, `lastAddedMembers.emails`, action.emails);
      oState = ReduxUtils.replace(oState, `lastAddedMembers.role`, action.role);
      return ReduxUtils.replace(oState, `lastAddedMembers.tip`, undefined);
		case BOARD_TEAM_ADD_MEMBER_DONE:
      oState = ReduxUtils.replace(oState, `lastAddedMembers.status`, 'DONE');
      oState = ReduxUtils.replace(oState, `lastAddedMembers.error`, undefined);
      return ReduxUtils.replace(oState, `lastAddedMembers.tip`, action.tip);
    case BOARD_TEAM_ADD_MEMBER_FAILED:
      oState = ReduxUtils.replace(oState, `lastAddedMembers.status`, 'FAILED');
      oState = ReduxUtils.replace(oState, `lastAddedMembers.error`, action.error);
      return ReduxUtils.replace(oState, `lastAddedMembers.tip`, undefined);
    case BOARD_TEAM_CHANGE_MEMBER_ROLE:
      oState = ReduxUtils.replace(oState, `changeRole.${action.userId}.status`, 'IN_PROGRESS');
      oState = ReduxUtils.replace(oState, `changeRole.${action.userId}.error`, undefined);
      oState = ReduxUtils.replace(oState, `changeRole.${action.userId}.tip`, undefined);
      oState = ReduxUtils.replace(oState, `changeRole.${action.userId}.boardId`, action.boardId);
      return ReduxUtils.replace(oState, `changeRole.${action.userId}.role`, action.newRole);
		case BOARD_TEAM_CHANGE_MEMBER_ROLE_DONE:
      oState = ReduxUtils.replace(oState, `changeRole.${action.userId}.status`, 'DONE');
      oState = ReduxUtils.replace(oState, `changeRole.${action.userId}.error`, undefined);
      return ReduxUtils.replace(oState, `changeRole.${action.userId}.tip`, action.tip);
    case BOARD_TEAM_CHANGE_MEMBER_ROLE_FAILED:
      oState = ReduxUtils.replace(oState, `changeRole.${action.userId}.status`, 'FAILED');
      oState = ReduxUtils.replace(oState, `changeRole.${action.userId}.error`, action.error);
      return ReduxUtils.replace(oState, `changeRole.${action.userId}.tip`, undefined);
    default:
      return state;
  }
};